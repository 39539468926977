<template lang="pug">
  BaseDialog.team-font-pairing__modal(
    align="left"
    :is-show="isShowFontPairingModal"
    :no-cancel="true"
    :is-full-width-buttons="true"
    button1Text="Apply Changes"
    @confirm="applyFontPairing"
    @closeOverlay="$emit('close-font-pairing-modal')"
  )
    h4.modal-title Font pairings
    p.modal-subtitle This will overwrite your existing text styles.
    ul.font-pairing__lists
      li.pairing-list(
        v-for="(font,index) in teamFontPairingList"
        :key="index"
        :class="{'is-active': selectedList(font)}"
        @click="selectList(font)"
      )
        p(:style="{fontWeight: font.h1.fontWeight, fontFamily: font.h1.fontFamily}") {{font.h1.fontFamily}}
        p(:style="{fontWeight: font.h2.fontWeight, fontFamily: font.h2.fontFamily}") {{font.h2.fontFamily}}
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';

export default {
  name: 'FontPairingModal',
  props: {
    isShowFontPairingModal: { type: Boolean, default: false },
  },
  data() {
    return {
      selectedFont: {},
      selectedTitle: '',
      selectedSubtitle: '',
      headingHasItsOwnFontWeight: true,
      subHeadingHasItsOwnFontWeight: true,
    };
  },
  computed: {
    ...mapState('team', ['teamFontPairingList', 'teamBrandFontStyles']),
    ...mapGetters('canvasElements', ['getProjectBrandId']),
    ...mapGetters(['getActiveOffeoFonts']),
  },
  mounted() {
    if (this.teamFontPairingList.length) {
      this.selectedFont = this.teamFontPairingList[0]; // eslint-disable-line
      this.selectedTitle = this.teamFontPairingList[0].h1.fontFamily;
      this.selectedSubtitle = this.teamFontPairingList[0].h2.fontFamily;
    }
  },
  methods: {
    ...mapActions('team', ['updateTeamBrandFontStyle']),
    selectedList(font) {
      return (
        font.h1.fontFamily === this.selectedTitle && font.h2.fontFamily === this.selectedSubtitle
      );
    },
    selectList(font) {
      this.selectedFont = font;
      this.selectedTitle = font.h1.fontFamily;
      this.selectedSubtitle = font.h2.fontFamily;
    },
    checkIfHasFontWeight(type) {
      const isHeading = type === 'heading';
      const selectedFont = isHeading ? this.selectedFont.h1 : this.selectedFont.h2;

      const fontWeight = selectedFont.fontWeight; // eslint-disable-line
      const isItalicFont = selectedFont.fontStyle === 'italic';
      const selectedFontObj = this.getActiveOffeoFonts.find(
        (font) => font.fontFamily === selectedFont.fontFamily // eslint-disable-line
      );
      const availableFontWeights = (selectedFontObj && selectedFontObj.weight) || [];
      const availableItalicWeights = (selectedFontObj && selectedFontObj.italic) || [];

      const availableWeights = isItalicFont ? availableItalicWeights : availableFontWeights;

      if (isHeading) {
        this.headingHasItsOwnFontWeight = availableWeights.includes(parseInt(fontWeight)); // eslint-disable-line
      } else {
        this.subHeadingHasItsOwnFontWeight = availableWeights.includes(parseInt(fontWeight)); // eslint-disable-line
      }
    },
    applyFontPairing() {
      /*
      compare selected heading / sub-heading font's font-weight with the font-weight the font has in 'offeo json'.
      if the selected font (e.g Abel) font-weight is 700, but in 'offeo json' only has 400 & 500, then set font-weight to regular (400).
      */
      this.checkIfHasFontWeight('heading');
      this.checkIfHasFontWeight('sub-heading');

      const fontWeightObj = {
        h1: this.selectedFont.h1.fontWeight,
        h2: this.selectedFont.h2.fontWeight,
      };

      if (!this.headingHasItsOwnFontWeight) {
        fontWeightObj.h1 = 400; // if heading's font-weight doesn't exist in offeo fonts.json then set to 400/regular
      }
      if (!this.subHeadingHasItsOwnFontWeight) {
        fontWeightObj.h2 = 400; // if heading's font-weight doesn't exist in offeo fonts.json then set to 400/regular
      }

      const fontDetailObj = (type) => {
        const isHeading = type === 'heading';

        const selectedFont = isHeading ? this.selectedFont.h1 : this.selectedFont.h2;
        const selectedData = isHeading
          ? this.teamBrandFontStyles.selected_h1
          : this.teamBrandFontStyles.selected_h2;
        const fontWeight = isHeading ? fontWeightObj.h1 : fontWeightObj.h2;

        return {
          fontStyle: selectedFont.fontStyle,
          fontWeight: parseInt(fontWeight), // eslint-disable-line
          fontSize: selectedData.fontSize,
          fontColor: selectedData.fontColor,
          fontFamily: selectedFont.fontFamily,
          category: selectedData.category,
          align: selectedData.align,
          letterSpacing: selectedData.letterSpacing,
          lineHeight: selectedData.lineHeight,
        };
      };

      this.updateTeamBrandFontStyle({
        type: 'heading',
        brandId: this.getProjectBrandId,
        fontDetail: fontDetailObj('heading'),
        from: 'font-pairing',
      });

      this.updateTeamBrandFontStyle({
        type: 'sub-heading',
        brandId: this.getProjectBrandId,
        fontDetail: fontDetailObj('sub-heading'),
        from: 'font-pairing',
      });

      this.$emit('close-font-pairing-modal');
    },
  },
};
</script>

<style lang="scss">
.team-font-pairing__modal {
  .modal-title {
    font-size: 1.25rem;
    font-weight: 600;
    color: var(--darkgrey800-white);
    margin: 0;
  }

  .font-pairing__lists {
    padding: 0;
    margin: 0 0 40px 0;
    max-height: 350px;
    overflow: auto;

    .pairing-list {
      padding: 10px 20px;
      background: var(--lightgrey300-darkgrey600);
      border-radius: 4px;
      margin-bottom: 15px;
      cursor: pointer;
      width: 95%;
      list-style-type: none;

      &.is-active {
        border: 1px solid var(--blue700-green600);
      }

      &:last-of-type {
        margin-bottom: 0;
      }

      p {
        color: var(--darkgrey700-white);
        font-size: 1rem;
        font-weight: 700;
        margin: 0;
      }
    }
  }

  .modal-subtitle {
    font-size: 0.875rem;
    font-weight: 500;
    color: $red600;
    margin: 15px 0;
  }

  .dialog-modal__content {
    width: 375px;
    padding: 30px;
    border: 0 !important;
    background: var(--white-darkgrey700);
  }

  .dialog-modal__buttons {
    .btn {
      max-width: initial;
      transition: 0.1s ease-in-out;

      &:hover {
        color: $blue2;
        background: $white;
        transition: 0.1s ease-in-out;
      }
    }
  }

  * {
    &::-webkit-scrollbar {
      width: 7px;
      height: 7px;
    }

    ::-webkit-scrollbar-track {
      background-color: rgba($darkGrey600, 0);
    }

    &::-webkit-scrollbar-thumb {
      background: var(--lightgrey500-black800);
      transition: background 0.25s ease-in-out;
      border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: var(--lightgrey500-black800); // so styling from Project.vue for scrollbar hover not applying
    }
  }
}
</style>
