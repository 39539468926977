<template lang="pug">
CanvasModal.font-overlay(
  :is-show="showBrandTabFontOverlay"
  :class="{'overlay--dark': isDarkMode}"
  title="Edit font style"
  @close="closeOverlay"
)
  template(#top)
    div.header-text
      p You can set your own text styles or
      button.font-pairing__button(
        type="button"
        @click="isShowFontPairingModal = true"
      ) choose from font pairings
    BaseButton(
      :is-primary="true"
      @click="closeOverlay"
    ) Done

  .font-overlay__content
    BasePreloader(
      v-if="isWholeTeamFontStyleLoading"
      :type="12"
    )
    .font-overlay__main(v-else)
      BrandTabFontGroup(
        type="heading"
        :is-active="activeFont === 'h1'"
        :font="getH1"
        @show="setActiveFont('h1')"
        @hide="resetActiveFont()"
      )
      BrandTabFontGroup(
        type="sub-heading"
        :is-active="activeFont === 'h2'"
        :font="getH2"
        @show="setActiveFont('h2')"
        @hide="resetActiveFont()"
      )

      div(v-if="teamBrandFontStyles.text_style && teamBrandFontStyles.text_style.length")
        BrandTabFontGroup(
          v-for="textObj in teamBrandFontStyles.text_style"
          :key="textObj.title"
          :type="textObj.title"
          :is-active="activeFont === textObj.title"
          :font="textObj"
          @show="setActiveFont(textObj.title)"
          @hide="resetActiveFont()"
          @delete-text="setActiveDeleteTextTitle($event)"
        )

      BaseButton.font__add-style-button(
        :is-hollow="true"
        icon-before="icon-plus-thin"
        @click="addNewFontStyle"
      ) Add new style

    .font-overlay__sidebar
      .info
        p.title All Custom Fonts
        p.subtitle Custom fonts will appear in the font’s list in the canvas. Supports .ttf and .otf formats only.

        BasePreloader.custom-font__loader(
          v-if="isCustomFontLoading"
          :width="50"
        )
        ul.team__custom-font-lists(v-else-if="teamCustomFonts.length")
          li.custom-font-list(v-for="font in teamCustomFonts" :key="font.font_family")
            p.custom-text(:style="{ 'font-family': font.font_family }") {{font.text}}
            div.custom-font__buttons
              button.icon-edit(
                type="button"
                title="Edit Custom Font"
                @click="editCustomFont(font)"
              )
              button.icon-trash-solid(
                type="button"
                title="Delete Custom Font"
                @click="showDeleteCustomFontModal(font)"
              )

        div.team-upload-font__wrapper(v-if="!isShowUploadFontModal && !isCustomFontLoading")
          BaseButton.team-font__upload-font(
            @click="isShowUploadFontModal = true"
          ) Upload a font

      BrandTabFontUpload(
        v-if="isShowUploadFontModal"
        :is-edit-custom-font="isEditCustomFont"
        :uploaded-font-files="uploadedFontFiles"
        :edited-italic-font-files="editedItalicFontFiles"
        :edit-font-details="editFontDetails"
        @close-modal="closeUploadFontModal"
        @add-font="addTeamFont"
        @delete-font="deleteTeamFont"
        @show-delete-custom-font-modal="showDeleteCustomFontModal($event)"
        @reset-edit-custom-font-data="resetEditCustomFont"
      )

      BaseDialog.delete-custom-font__modal(
        :is-show="isShowDeleteCustomFont"
        :is-danger="true"
        :title="deleteCustomFontTitle"
        @closeOverlay="isShowDeleteCustomFont = false"
        @confirm="doDeleteCustomFont"
      )

    BaseDialog(
      :is-show="activeDeleteTextTitle !== ''"
      :is-danger="true"
      :title="deleteTextFontStyleTitle"
      @closeOverlay="setActiveDeleteTextTitle('')"
      @confirm="deleteTextFontStyle"
    )

    FontPairingModal(
      v-if="isShowFontPairingModal"
      :is-show-font-pairing-modal="isShowFontPairingModal"
      @close-font-pairing-modal="isShowFontPairingModal = false"
    )
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'; // eslint-disable-line

import BrandTabFontGroup from '@/components/ProjectCanvas/BrandTab/BrandTabFontGroup.vue';
import BrandTabFontUpload from '@/components/ProjectCanvas/BrandTab/BrandTabFontUpload.vue';
import FontPairingModal from '@/components/ProjectCanvas/BrandTab/children/FontPairingModal.vue';

import darkModeMixin from '@/components/mixins/dark-mode-mixins';

export default {
  name: 'BrandTabFontOverlay',
  mixins: [darkModeMixin],
  components: {
    BrandTabFontGroup,
    BrandTabFontUpload,
    FontPairingModal,
  },
  data() {
    return {
      activeFont: '',
      isShowUploadFontModal: false,
      isShowFontPairingModal: false,

      uploadedFontFiles: [],
      editedItalicFontFiles: [],
      editFontDetails: {},
      isEditCustomFont: false,

      activeDeleteCustomFontName: '',
      activeDeleteCustomFontFamily: '',
      isShowDeleteCustomFont: false,
      activeDeleteTextTitle: '',
    };
  },
  computed: {
    ...mapGetters('canvasElements', ['getProjectBrandId']),
    ...mapGetters(['getActiveOffeoFonts']),
    ...mapState(['showBrandTabFontOverlay']),
    ...mapState('team', ['teamBrandIndividualLoadingType', 'teamBrandFontStyles', 'teamCustomFontsByBrand']),
    getH1() {
      return this.teamBrandFontStyles.selected_h1;
    },
    getH2() {
      return this.teamBrandFontStyles.selected_h2;
    },
    teamCustomFonts() {
      const selectedTeamBrand = this.teamCustomFontsByBrand.find(teamBrand => teamBrand.brand_id === this.getProjectBrandId);
      return (selectedTeamBrand && selectedTeamBrand.custom_font_list) || [];
    },
    isWholeTeamFontStyleLoading() {
      return this.teamBrandIndividualLoadingType === 'whole-team-font-style';
    },
    isCustomFontLoading() {
      return this.teamBrandIndividualLoadingType === 'custom-font';
    },

    deleteCustomFontTitle() {
      return `Are you sure want to delete ${this.activeDeleteCustomFontName} Font ?`;
    },
    deleteTextFontStyleTitle() {
      return `Are you sure want to delete ${this.activeDeleteTextTitle} font style ?`;
    },
  },
  methods: {
    ...mapActions('team', ['deleteCustomFonts', 'addTeamBrandFontStyle', 'deleteTeamBrandFontStyle']),
    ...mapMutations(['setShowBrandTabFontOverlay']),
    setActiveFont(value) {
      this.activeFont = value;
    },
    resetActiveFont() {
      this.activeFont = '';
    },
    closeOverlay() {
      this.setShowBrandTabFontOverlay(false);
    },
    addNewFontStyle() {
      this.addTeamBrandFontStyle(this.getProjectBrandId);
    },
    setActiveDeleteTextTitle(title) {
      this.activeDeleteTextTitle = title;
    },
    deleteTextFontStyle() {
      this.deleteTeamBrandFontStyle({
        brandId: this.getProjectBrandId,
        styleName: this.activeDeleteTextTitle,
      });
      this.setActiveDeleteTextTitle('');
    },


    editCustomFont(font) {
      // since we can select another custom-font when editing a custom-font, so need to reset to make sure feature not spoil.
      this.uploadedFontFiles = [];
      this.editedItalicFontFiles = [];

      this.isEditCustomFont = true;

      const formattedFonts = [...font.fonts];
      formattedFonts.forEach((item) => {
        if (!item.italic) {
          item.name = item.file_name; // eslint-disable-line
          item.initialData = true; // eslint-disable-line
        }

        if (item.italic) {
          this.editedItalicFontFiles.push({
            name: item.file_name,
            file: item.file,
            weight: item.weight,
            initialData: true,
          });
        }
      });

      // since already have 'editedItalicFontFiles', remove italic files inside formattedFonts
      const filteredFonts = formattedFonts.filter((item) => !item.italic); // eslint-disable-line

      this.uploadedFontFiles = filteredFonts;
      this.editFontDetails = font;
      this.isShowUploadFontModal = true;
    },
    closeUploadFontModal() {
      this.isShowUploadFontModal = false;
      this.resetEditCustomFont();
    },
    resetEditCustomFont() {
      this.uploadedFontFiles = [];
      this.editedItalicFontFiles = [];
      this.editFontDetails = {};
      this.isEditCustomFont = false;
    },
    addTeamFont(data) {
      this.uploadedFontFiles = [...data].concat(this.uploadedFontFiles); // concat needs to be array with array.
    },
    deleteTeamFont(data) {
      if (data.isItalicFont) {
        this.editItalicFontFiles.splice(data.index, 1);
      } else {
        this.uploadedFontFiles.splice(data.index, 1);
      }
    },

    showDeleteCustomFontModal(activeFont) {
      this.activeDeleteCustomFontName = activeFont.text;
      this.activeDeleteCustomFontFamily = activeFont.font_family;
      this.isShowDeleteCustomFont = true;
    },
    doDeleteCustomFont() {
      const currentEditedFontFamily = this.editFontDetails && this.editFontDetails.font_family;

      this.deleteCustomFonts({
        fontFamily: this.activeDeleteCustomFontFamily,
        brandId: this.getProjectBrandId,
      });

      if (this.activeDeleteCustomFontFamily === currentEditedFontFamily) {
        // if deleted a custom font that is editing, reset & close
        this.isShowUploadFontModal = false;
        this.resetEditCustomFont();
      }

      this.activeDeleteCustomFontName = '';
      this.activeDeleteCustomFontFamily = '';
      this.isShowDeleteCustomFont = false;
    },
  },
};
</script>

<style lang="scss">
.font-overlay {
  &.overlay--dark {
    .content--top {
      box-shadow: 0px 18px 18px rgba(10, 31, 68, 0.35), 0px 0px 1px rgba(10, 31, 68, 0.3);
    }
  }

  .canvas-modal__header {
    background: var(--blue700-darkgrey900);
    color: $white;
  }

  .content--top {
    background: var(--lightgrey100-darkgrey700);
    color: var(--black-white);
  }

  .canvas-modal__content {
    background: var(--white-darkgrey700);
  }

  .content {
    height: 500px;
    background: var(--white-darkgrey700);
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 7px;
      height: 7px;
    }

    &::-webkit-scrollbar-track {
      background-color: rgba($darkGrey600, 0) !important;
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--lightgrey500-black800) !important;
      border-radius: 4px;
    }
  }

  .canvas-modal__closebtn {
    background: transparent !important;
  }

  .header-text {
    display: flex;
  }

  .font-pairing__button {
    color: $blue600;
    text-decoration: underline;
    font-weight: 500;
    transition: color 0.3s;

    &:hover {
      color: $blue800;
    }
  }
}

.font-overlay__content {
  display: flex;
  align-content: flex-start;
  align-items: flex-start;
  margin-top: 10px;
}

.font-overlay__main {
  width: 65%;
  text-align: left;

  .font__add-style-button {
    height: 45px;
    width: 180px;
    font-size: 0.8125rem;
    color: var(--darkgrey900-white);

    &:hover {
      color: $darkGrey900;
    }

    .btn__content {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.font-overlay__sidebar {
  width: 35%;
  padding-left: 30px;
  text-align: left;

  .info {
    .title {
      font-size: 1.125rem;
      color: var(--darkgrey800-white);
      font-weight: 500;
      margin-top: 0;
    }

    .subtitle {
      font-size: 0.75rem;
      line-height: 1.75;
      font-weight: 500;
      color: var(--darkgrey400-lightgrey700);
    }
  }

  .custom-font__loader {
    height: 50px;
    align-content: start;
  }

  .team__custom-font-lists {
    padding: 10px 15px;
    border: 1px solid $lightGrey400;
    margin-bottom: 20px;

    .custom-font-list {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: var(--darkgrey800-white);
      list-style: none;
      padding: 5px 0;
      margin-bottom: 5px;

      &:hover {
        .custom-font__buttons {
          opacity: 1;
          visibility: visible;
        }
      }
    }

    .custom-text {
      font-weight: 400;
      font-size: 0.875rem;
      margin: 0;
    }

    .custom-font__buttons {
      display: flex;
      margin-left: 10px;
      visibility: hidden;
      opacity: 0;
      transition: opacity 0.3s ease-in-out;

      .icon-edit,
      .icon-trash-solid {
        color: var(--darkgrey800-white);
        padding: 0 4px;
        margin: 0 4px;
      }

      .icon-edit {
        font-size: 1rem;
      }

      .icon-trash-solid {
        font-size: 0.75rem;
      }
    }
  }

  .team-upload-font__wrapper {
    height: 38px;
    width: 150px;

    .team-font__upload-font {
      height: 38px;
      width: 150px;
      font-size: 0.875rem;
      background: $lightGrey300;
      color: $darkGrey800;
      display: inline-block;
      vertical-align: top;
      transition: background 0.15s ease-in-out;

      &:hover {
        background: $lightGrey400;
      }
    }
  }

  .delete-custom-font__modal {
    .dialog-modal__title {
      margin: 0;
    }
  }
}
</style>
