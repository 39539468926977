<template lang="pug">
.font-group(
  :class="{'group--low-opacity': isLoading}"
)
  .font-group__summary
    .text {{ text }}
    .font__list-buttons
      button.delete-text(v-if="isTextFontStyle" type="button" @click="$emit('delete-text', type)") Delete
      BaseButton.icon-edit(
        :is-icon="true"
        title="Edit"
        @click="toggleIsShowFontFamilyEdit"
      )

  BasePreloader.font-group__loader(
    v-if="isLoading"
    :type="12"
  )
  .font-group__edit(
    v-show="isShowFontFamilyEdit"
    v-click-outside="hideIsShowFontFamilyEdit"
  )
    .list-column.list--left
      .list__header
        .font-group__family(
          :class="{ 'is-active': isShowFontFamilyList }"
          v-click-outside="onClickOutsideFontFamily"
        )
          BaseButton.dropdown-button.selected(
            :is-hollow="true"
            icon-after="icon-down-arrow"
            :is-canvas="true"
            @click="toggleIsShowFontFamilyList"
          ) {{ fontFamilyText }}
          FontFamilyDropdown(
            v-show="isShowFontFamilyList"
            :is-brand-font="true"
            :font="{ fontFamily }"
            @select="updateFontFamily"
          )

        FontWeightUploadDropdown(
          direction="right"
          :custom-options="true"
          :custom-font-weights="availableFontWeights"
          :custom-italic-font-weights="availableItalicFontWeights"
          :font-weight-value="fontWeight"
          :is-italic-value="isItalicFontPreview"
          @change="changeFontStyle($event)"
        )

        BaseInputField.input(
          placeholder="Font Size"
          v-model="fontSize"
          @blur="saveFontSizeChange"
        )

      div.list__subheader
        //- add v-if so the other brand font style section's v-click-outside not get triggered
        .font-color(
          v-if="isShowFontFamilyEdit"
          v-click-outside="onClickOutsideColorPicker"
        )
          button.color-dot.font-color__dot(
            type="button"
            :style="{ background: fontColor }"
            @click="isColorPickerShow = true"
          )
          Photoshop.font-color__picker(
            v-show="isColorPickerShow"
            :value="fontColor"
            @input="updateFontPreview('color', $event)"
          )

        button.subheader-icon.icon-bold(
          type="button"
          :class="{'is-active': isBoldFontPreview, 'is-disabled': !isAbleToToggleBold}"
          @click="updateFontPreview('bold')"
        )
        button.subheader-icon.icon-italic(
          type="button"
          :class="{'is-active': isItalicFontPreview, 'is-disabled': !isAbleToToggleItalic}"
          @click="updateFontPreview('italic')"
        )
        button.subheader-icon(
          type="button"
          :class="alignButtonClass"
          @click="updateFontPreview('align')"
        )

      BaseSlider.list-slider(
        :min="-20"
        :max="50"
        title="Letter Spacing"
        :value="letterSpacing"
        @update="updateLetterSpacing"
      )
      BaseSlider.list-slider(
        :min="0.5"
        :max="3"
        :step="0.1"
        title="Line Height"
        :value="lineHeight"
        @update="updateLineHeight"
      )

    .list-column.list--right
      .team-font__preview-text(
        ref="preview"
        :style="fontPreviewStyle"
      )
        span(:style="textPreviewStyle") Hi, welcome to OFFEO. It’s a bright sunny day out here.
      p.team-font__preview-description Text Preview (1080x1080)
</template>

<script>
import { mapGetters, mapState, mapMutations, mapActions } from 'vuex'; // eslint-disable-line
import { Photoshop } from 'vue-color';
import { debounce } from 'debounce';

import {
  getHasFontWeight,
  getHasBoldFontWeight,
  getHasItalicFontWeight,
} from '@/assets/scripts/utilities';
import { fontWeights } from '@/assets/scripts/enums';
import { defaultFallbackFont } from '@/assets/scripts/variables';

import FontFamilyDropdown from '@/components/ProjectCanvas/ControlBar/children/FontFamilyDropdown.vue';
import FontWeightUploadDropdown from './children/FontWeightUploadDropdown.vue';

export default {
  name: 'BrandTabFontGroup',
  components: {
    Photoshop,
    FontFamilyDropdown,
    FontWeightUploadDropdown,
  },
  props: {
    type: { type: String, default: '' },
    font: { type: Object, default: () => {} },
    isActive: { type: Boolean, default: false },
  },
  data() {
    return {
      isShowFontFamilyEdit: false,
      isShowFontFamilyList: false,
      previewWidth: 0,
      isColorPickerShow: false,
      hasExistingFontWeight: true,

      fontFamily: 'Work Sans',
      fontSize: 100,
      fontStyle: 'normal',
      fontColor: '#000000',
      fontWeight: 400,
      category: 'latin',
      isBold: false,
      isItalic: false,
      align: 'left',
      letterSpacing: 0,
      lineHeight: 1,
    };
  },
  computed: {
    ...mapState('team', ['teamBrandIndividualLoadingType']),
    ...mapGetters('canvasElements', ['getProjectBrandId']),
    ...mapGetters(['getActiveOffeoFonts']),
    isTextFontStyle() {
      return this.type.includes('text');
    },
    text() {
      if (this.fontFamily) {
        return `${this.type} / ${this.fontFamilyText} / ${this.actualFontPreviewStyle} / ${this.fontSize}`;
      }
      return `${this.type} / - / - / -`;
    },
    isLoading() {
      return this.teamBrandIndividualLoadingType === 'team-font-style' && this.isShowFontFamilyEdit;
    },
    fontFamilyText() {
      // fontFamily is not the same as fontFamilyText
      // fontFamily is auto generated when user upload
      const fontObj = this.selectedFontObj;
      let fontFamilyText = 'Work Sans';
      if (fontObj) {
        fontFamilyText = fontObj.text;
      }

      return fontFamilyText;
    },
    availableFontWeights() {
      return this.selectedFontObj.weight;
    },
    availableItalicFontWeights() {
      return this.selectedFontObj.italic;
    },
    selectedFontObj() {
      const fallbackFontObject = this.getActiveOffeoFonts.find((font) => font.fontFamily === defaultFallbackFont); // eslint-disable-line
      const selectedFontObject = this.getActiveOffeoFonts.find(
        (font) => font.fontFamily === this.fontFamily // eslint-disable-line
      );

      return selectedFontObject || fallbackFontObject;
    },
    hasBoldFontWeight() {
      const isItalic = this.fontStyle === 'italic';

      if (isItalic) return getHasItalicFontWeight(700, this.selectedFontObj); // eslint-disable-line
      return getHasBoldFontWeight(this.selectedFontObj);
    },
    isAbleToToggleBold() {
      const isBold = this.fontWeight === 700;
      const isItalic = this.fontStyle === 'italic';

      if (isBold && isItalic) return getHasItalicFontWeight(400, this.selectedFontObj);
      else if (isBold) return getHasFontWeight(400, this.selectedFontObj); // eslint-disable-line
      else if (isItalic) return getHasItalicFontWeight(700, this.selectedFontObj); // eslint-disable-line
      return getHasBoldFontWeight(this.selectedFontObj);
    },
    isBoldFontPreview() {
      return this.fontWeight === 700 && this.hasBoldFontWeight;
    },
    hasItalicFontWeight() {
      return getHasItalicFontWeight(this.fontWeight, this.selectedFontObj);
    },
    isAbleToToggleItalic() {
      const isItalic = this.fontStyle === 'italic';
      if (isItalic) return getHasFontWeight(this.fontWeight, this.selectedFontObj);
      return getHasItalicFontWeight(this.fontWeight, this.selectedFontObj);
    },
    isItalicFontPreview() {
      const isItalicFontStyle = this.fontStyle === 'italic' && this.hasItalicFontWeight;
      return isItalicFontStyle;
    },
    actualFontPreviewStyle() {
      let selectedFontHasFontWeight = false;
      let fontType = fontWeights[this.fontWeight];

      if (this.isItalicFontPreview) selectedFontHasFontWeight = getHasFontWeight(this.fontWeight, this.selectedFontObj, 'italic');
      else selectedFontHasFontWeight = getHasFontWeight(this.fontWeight, this.selectedFontObj);

      if (this.isItalicFontPreview) {
        fontType = fontType + '-italic'; // eslint-disable-line
      }

      if (!selectedFontHasFontWeight) {
        fontType = 'regular';
      }

      return fontType;
    },

    fontPreviewStyle() {
      const object = {};

      if (this.fontFamily) {
        object.fontFamily = `${this.fontFamily}, Work Sans, Arial, Helvetica`;
      }

      if (this.fontSize) {
        const fontSize = parseInt(this.fontSize, 10);

        object.fontSize = `${fontSize}px`;
      }

      if (this.fontColor) {
        object.color = this.fontColor;
      }

      if (this.fontWeight) {
        object.fontWeight = this.fontWeight;
      }

      if (this.isItalicFontPreview) {
        object.fontStyle = 'italic';
      }

      if (this.align === 'left') {
        object.textAlign = 'left';
      } else if (this.align === 'center') {
        object.textAlign = 'center';
      } else if (this.align === 'right') {
        object.textAlign = 'right';
      }

      if (this.lineHeight) {
        object.lineHeight = this.lineHeight;
      }

      return object;
    },
    textPreviewStyle() {
      const object = {};
      const ratio = this.previewWidth / 1080;

      if (this.letterSpacing) {
        const letterSpacing = this.calcLetterSpacing(this.letterSpacing, this.fontSize);
        object.letterSpacing = `${letterSpacing}em`;
      }

      object.transform = `scale(${ratio})`;

      return object;
    },
    alignButtonClass() {
      let directionClass = 'left1';
      if (this.align === 'center') {
        directionClass = 'center1';
      } else if (this.align === 'right') {
        directionClass = 'right1';
      }

      return 'icon-align-' + directionClass; // eslint-disable-line
    },
  },
  watch: {
    isActive(val) {
      this.isShowFontFamilyEdit = val;
      if (val) {
        this.$nextTick(() => {
          this.getPreviewWidth();
        });
      }
    },

    font() {
      // handle change from font-pairing
      this.setupFontPreview();
    },
  },
  beforeMount() {
    if (this.font) {
      this.setupFontPreview();
    }
  },
  mounted() {
    this.getPreviewWidth();
  },
  methods: {
    ...mapMutations('team', ['setTeamBrandIndividualLoadingType']),
    ...mapActions('team', ['updateTeamBrandFontStyle']),
    setupFontPreview() {
      // setting up from font.fontFamily to this.fontFamily etc
      for (let i = 0; i < Object.keys(this.font).length; i += 1) {
        const key = Object.keys(this.font)[i];

        if (this.font[key] && typeof this[key] !== 'undefined') {
          this[key] = this.font[key];

          // check for unexist font-family, if there is any - make sure the font-family will get updated once there is any changes in font-preview.
          this.checkFontFamilyExistence();
        }
      }
    },
    checkFontFamilyExistence() {
      const isFontFamilyExist = this.getActiveOffeoFonts.find(
        (font) => font.fontFamily === this.fontFamily // eslint-disable-line
      );

      if (!isFontFamilyExist) {
        this.fontFamily = 'Work Sans';
      }
    },
    toggleIsShowFontFamilyEdit() {
      this.onClickOutsideColorPicker();

      if (!this.isShowFontFamilyEdit) {
        this.setTeamBrandIndividualLoadingType('');
        this.$emit('show');
      } else {
        this.$emit('hide');
      }
    },
    hideIsShowFontFamilyEdit(event) {
      /* eslint-disable */
      const paths = event.path || event.composedPath();
      const isTriggerIcon =
        paths.findIndex((path) => {
          return (
            path.className &&
            typeof path.className === 'string' &&
            path.className.includes('font-group__summary')
          );
          // to make sure the result true/false
        }) > -1;
      /* eslint-enable */

      // only close if the brand-font-style open, so the other sections won't get affected.
      if (!isTriggerIcon && this.isShowFontFamilyEdit && !this.isLoading) {
        this.onClickOutsideColorPicker();
        this.$emit('hide');
      }
    },
    toggleIsShowFontFamilyList() {
      this.isShowFontFamilyList = !this.isShowFontFamilyList;
    },
    onClickOutsideFontFamily() {
      if (this.isShowFontFamilyList) {
        this.isShowFontFamilyList = false;
      }
    },
    onClickOutsideColorPicker() {
      this.isColorPickerShow = false;

      // if there is color change then do API integration
      const initialFontPreviewColor = this.font.fontColor;
      if (this.fontColor !== initialFontPreviewColor) {
        this.updateBrandFontStyle();
      }
    },
    getPreviewWidth() {
      const ref = this.$refs.preview;
      // 300 to cover sometimes scale is too small on first load when ref is missing
      this.previewWidth = (ref && ref.clientWidth) || 300;
    },
    calcLetterSpacing(paramLetterSpacing, fontSize) {
      let letterSpacing = 0;
      if (paramLetterSpacing && fontSize) {
        letterSpacing = paramLetterSpacing / fontSize;
      }
      return letterSpacing;
    },
    saveFontSizeChange() {
      const initialFontSize = this.font.fontSize;

      if (this.fontSize === initialFontSize) return;

      if (this.fontSize < 1) {
        // cover invalid values such as 0, -1, etc.
        this.fontSize = initialFontSize;
      }

      this.updateBrandFontStyle();
    },
    changeFontStyle(fontStyle) {
      if (fontStyle.subValue.includes('italic')) {
        this.fontStyle = 'italic';
      } else {
        this.fontStyle = 'normal';
      }

      // value received from teamFontDropdown can be 'light' / 'light-italic'
      if (fontStyle.value.includes('extra-light')) {
        this.fontWeight = 200;
      } else if (fontStyle.value.includes('light')) {
        this.fontWeight = 300;
      } else if (fontStyle.value.includes('medium')) {
        this.fontWeight = 500;
      } else if (fontStyle.value.includes('semi-bold')) {
        this.fontWeight = 600;
      } else if (fontStyle.value.includes('bold')) {
        this.fontWeight = 700;
      } else {
        this.fontWeight = 400;
      }

      this.updateBrandFontStyle();
    },
    updateFontPreview(type, data) {
      switch (type) {
        case 'bold':
          const obj = {}; // eslint-disable-line

          if (this.fontWeight === 700) {
            obj.value = 'regular';
            obj.subValue = this.isItalicFontPreview ? 'regular-italic' : 'regular';
          } else {
            obj.value = 'bold';
            obj.subValue = this.isItalicFontPreview ? 'bold-italic' : 'bold';
          }

          this.changeFontStyle(obj);

          break;
        case 'italic':
          this.fontStyle = this.isItalicFontPreview ? 'normal' : 'italic';
          this.updateBrandFontStyle();

          break;

        case 'align':
          if (this.align === 'left') {
            this.align = 'center';
          } else if (this.align === 'center') {
            this.align = 'right';
          } else {
            this.align = 'left';
          }

          this.updateBrandFontStyle();

          break;
        case 'color':
          this.fontColor = data.hex;

          break;
        default:
          break;
      }
    },
    updateFontFamily(font) {
      const { fontFamily, fontCategory } = font;
      this.fontFamily = fontFamily;
      this.category = fontCategory;
      this.updateBrandFontStyle();
      this.toggleIsShowFontFamilyList();
    },

    updateLetterSpacing(value) {
      this.letterSpacing = value;

      // to avoid repetitive API call
      this.updateLetterSpacingApiCall();
    },
    // eslint-disable-next-line
    updateLetterSpacingApiCall: debounce(function() {
      this.updateBrandFontStyle();
    }, 1500),

    updateLineHeight(value) {
      this.lineHeight = value;

      // to avoid repetitive API call
      this.updateLineHeightApiCall();
    },
    // eslint-disable-next-line
    updateLineHeightApiCall: debounce(function() {
      this.updateBrandFontStyle();
    }, 1500),

    checkFontPreviewFontWeight() {
      const fontWeight = this.fontWeight; // eslint-disable-line
      const isItalicFont = this.fontStyle === 'italic';
      const availableWeights = isItalicFont
        ? this.availableItalicFontWeights
        : this.availableFontWeights;

      this.hasExistingFontWeight = availableWeights.includes(fontWeight);
    },

    updateBrandFontStyle() {
      /*
      compare selected font / font-preview's font-weight with the existing heading/sub-heading/text font-weight.
      if the existing heading/sub-heading/text font-weight is 700, but the selected font only has 400 & 500,
      then set existing heading/sub-heading/text font-weight to regular (400) so can match.
      */
      this.checkFontPreviewFontWeight();

      if (!this.hasExistingFontWeight) {
        this.fontStyle = 'normal';
        this.fontWeight = 400;
      }

      const fontDetailObj = {
        fontStyle: this.fontStyle,
        fontWeight: this.fontWeight,
        fontSize: this.fontSize,
        fontColor: this.fontColor,
        fontFamily: this.fontFamily,
        category: this.category,
        align: this.align,
        letterSpacing: this.letterSpacing,
        lineHeight: this.lineHeight,
      };

      this.updateTeamBrandFontStyle({
        type: this.type,
        brandId: this.getProjectBrandId,
        fontDetail: fontDetailObj,
      });
    },
  },
};
</script>

<style lang="scss">
.font-group {
  position: relative;
  border: 1px solid $lightGrey400;
  padding: 10px 20px;
  border-radius: $componentBorderRadius;
  margin-bottom: 20px;

  &.group--low-opacity {
    .font-group__summary,
    .font-group__edit {
      pointer-events: none;
      opacity: 0.3;
    }
  }

  .font-group__loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .font-group__edit {
    display: flex;
  }

  .list-column {
    flex: 50%;

    &.list--left {
      padding-right: 20px;
    }

    &.list--right {
      max-width: 300px;
      display: flex;
      justify-content: center;
      flex-direction: column;
    }
  }

  .list__header {
    position: relative;
    width: 100%;

    .input {
      margin-left: 10px;
    }

    .input-field__wrapper .input-field {
      font-size: 0.75em;
      height: 30px;
      padding: 8px 10px;
      background: none;
      color: var(--darkgrey900-white);
    }
  }

  .list__header,
  .list__subheader {
    display: flex;
    align-items: center;

    .dropdown {
      width: 40%;
      margin-right: 10px;

      &.font-weight__dropdown {
        min-width: 130px;
      }
    }

    .input {
      width: 45px;
    }
  }

  .list__subheader {
    margin: 15px 0 25px;

    .color-dot {
      position: relative;
      width: 18px;
      height: 18px;
      border-radius: 50%;
      margin-right: 15px;
      outline: none;
    }

    .subheader-icon {
      background: var(--lightgrey200-black800);
      color: var(--darkgrey900-white);
      padding: 5px;
      font-size: 1.125rem;
      margin-right: 10px;
      border-radius: 4px;

      &.is-active {
        background: var(--darkgrey800-lightgrey100);
        color: var(--lightgrey200-black800);
      }

      &.is-disabled {
        pointer-events: none;
        color: var(--lightgrey600-darkgrey400);
      }
    }
  }

  .font-color {
    position: relative;
  }

  .font-color__picker {
    position: absolute;
    z-index: 4;
    top: 22px;
    left: 0;
    width: 12rem;
    background: var(--lightgrey200-black800);
  }

  .list-slider {
    display: flex;
    align-items: center;

    input[type='range']::-webkit-slider-thumb {
      background: $subBlue !important;
    }

    .slider__title {
      font-size: 0.8125rem;
      width: 35%;
      margin-right: 10px;
      color: var(--black-white);
    }

    .slider__container {
      width: 65%;

      .slider__value {
        color: var(--darkgrey800-white);
        outline: 0;

        &:focus {
          border-color: var(--blue700-white);
        }
      }
    }

    .slider__container .range-wrapper .range-before {
      background: $subBlue;
    }
  }

  // rewrite color-picker plugin classes
  .vc-ps-fields__hex {
    display: block !important;
    width: 9.5rem !important;

    .vc-input__input {
      margin-top: 0.25rem !important;
      margin-left: 0 !important;
      width: 100% !important;
      height: 1.5rem !important;
      font-size: 0.875rem !important;
      font-family: $baseFont !important;
      padding-left: 1.25rem !important;
      border: 1px solid $darkGrey8 !important;
      background-color: var(--lightgrey200-black800) !important;
      color: var(--black-white);
      box-shadow: none;
    }
    .vc-input__label {
      left: 0.625rem !important;
      top: 0.3125rem !important;
      font-size: 0.875rem !important;
      font-family: $baseFont !important;
      color: var(--black-white);
    }
  }
  .vc-ps-body {
    padding: 1.25rem 1.25rem 3rem !important;
  }
  .vc-ps-saturation-wrap {
    width: 100% !important;
    height: 8.75rem !important;
    border: 2px solid rgba(0, 0, 0, 0.1) !important;

    .vc-saturation-circle {
      width: 0.35rem !important;
      height: 0.35rem !important;
    }
  }
  .vc-ps-hue-wrap {
    width: 2rem !important;
    height: 8.75rem !important;
    margin-left: 0.5rem !important;
    border: 2px solid rgba(0, 0, 0, 0.1) !important;
  }
  .vc-hue-picker {
    width: 1.4rem !important;
    height: 0.3rem !important;
    background: none !important;
    border: 2px solid $light !important;
  }
  .vc-ps-actions {
    position: absolute !important;
    top: 10rem !important;
    left: 0 !important;
  }
  .vc-ps-controls {
    width: 0 !important;
    margin-left: 0 !important;
  }
  .vc-ps-head {
    display: none !important;
  }
  .vc-ps-previews {
    display: none !important;
  }
  .vc-ps-ac-btn {
    display: none !important;
  }
  .vc-ps-fields__divider {
    display: none !important;
  }
  .vc-editable-input {
    display: none;
  }
}

.font-group__summary {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .font__list-buttons {
    display: flex;
    align-items: center;

    .icon-edit {
      font-size: 1rem;
      color: var(--darkgrey800-white);

      .btn__circle {
        opacity: 0;
      }

      &:hover,
      &:focus {
        background: transparent;
      }
    }
  }

  .delete-text {
    font-size: 0.75rem;
    color: $red600;
    font-weight: 500;
    margin-right: 15px;
  }

  .text {
    font-size: 0.875rem;
    color: var(--darkgrey800-white);
    text-transform: capitalize;
    padding: 5px 0;
  }
}

.font-group__edit {
  margin-top: 10px;
}

.team-font__preview-text {
  position: relative;
  background: var(--lightgrey100-darkgrey600);
  overflow: hidden;

  &::before {
    content: '';
    display: block;
    padding-top: 100%;
  }

  span {
    position: absolute;
    top: 0;
    left: 0;
    width: 1080px;
    height: 1080px;
    margin: auto;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    padding: 30px;
    transform-origin: top left;
  }
}

.team-font__preview-description {
  color: var(--darkgrey400-lightgrey700);
  font-size: 0.75rem;
  font-weight: 500;
  margin: 15px 0 5px;
  text-align: center;
}

.font-group__family {
  position: relative;
  width: 120px;
  margin-right: 10px;

  .dropdown-button {
    width: 100%;
    text-align: start;
    padding: 8px 15px;
    justify-content: space-between;
    background: var(--lightgrey300-darkgrey5);
    border-color: var(--lightgrey300-darkgrey5);
    color: var(--black-white);

    &:active,
    &:hover {
      background: var(--lightgrey300-darkgrey5) !important;
      color: var(--black-white) !important;
    }

    i {
      position: absolute !important;
      right: 0;
    }
  }

  .fontfamily-dropdown {
    position: absolute;
    background: var(--lightgrey100-darkgrey4);
    width: 100%;
    height: auto;
    padding: 10px;
    z-index: 3;
    min-width: 250px;
    box-shadow: 0px 2px 4px 5px rgb(10 31 68 / 10%), 0px 0px 2px rgb(10 31 68 / 8%);

    &.is-light {
      box-shadow: 0px 26px 26px rgba(10, 31, 68, 0.12), 0px 0px 1px rgba(10, 31, 68, 0.1);
    }

    .fontcategory {
      margin-bottom: 0;
    }

    .dropdown__upload-tab-container,
    .fontfamily {
      max-height: 200px;
      overflow: hidden;
      overflow-y: auto;
    }

    .fontfamily {
      margin: 0 -10px;
      width: calc(100% + 20px);
      padding: 10px 10px 0;
    }
  }
}
</style>
