<template lang="pug">
.font-overlay__upload
  BaseButton.icon.icon-cancel(
    :is-icon="true"
    :plain-hover="true"
    @click="closeFontModal({isResetEditCustomFont: true})"
  )
  p.title {{tabTitle}}
  .upload__group
    BaseInputField.upload__input(
      :is-error="isMissingName"
      v-model="fontNameInput"
      placeholder="Enter font name"
    )
    .add-team-custom-font__button
      input.team-font__file-upload-btn(
        type="file"
        ref="fontUploadInput"
        accept=".ttf, .otf, .woff"
        multiple="multiple"
        @change="addFonts"
      )
      BaseButton.upload__add-font(
        :is-primary="true"
        icon-before="icon-add"
      ) Add new

  .upload__list(v-if="!isEmptyFontFile")
    p.title font style name or set weight
    span.content__alert-notification(
      v-if="hasClickedSubmit && !isFontStyleListFilled"
    ) Please select the closest font weight to match with your font.

    ul.uploaded-font__lists
      li.list(v-for="(font, index) in uploadedFontFiles" :key="`${font.name}-${font.lastModified}`" )
        p.list-text(:title="font.name") {{font.name}}
        .list-buttons
          button.icon-trash-solid(type="button" @click="deleteTeamFont(index,font)")
          FontWeightUploadDropdown.dropdown(
            :is-empty="isFontStyleNotSelected(font.name)"
            :font-weight-value="font.weight"
            :is-italic-value="false"
            :start-from-empty-value="!isEditCustomFont || isNotInitialData(font)"
            :selected-style-list="fontStyleList"
            @change="changeFontStyle($event, font)"
            @open="moveScroller($event)"
          )

      li.list(v-for="(font, index) in editedItalicFontFiles" :key="`${font.name}-${font.lastModified}`" )
        p.list-text(:title="font.name") {{font.name}}
        .list-buttons
          button.icon-trash-solid(type="button" @click="deleteTeamFont(index,font, true)")
          FontWeightUploadDropdown.dropdown(
            :is-empty="isFontStyleNotSelected(font.name)"
            :font-weight-value="font.weight"
            :is-italic-value="true"
            :start-from-empty-value="!isEditCustomFont || isNotInitialData(font)"
            :selected-style-list="fontStyleList"
            @change="changeFontStyle($event, font)"
            @open="moveScroller($event)"
          )

  p.empty-text(v-else)
    | Supports .otf and .ttf <br/>
    | formats only

  .upload-button__wrapper(v-if="isEditCustomFont")
    BaseButton(
      :is-danger="true"
      @click="$emit('show-delete-custom-font-modal', editedFontData)"
    ) Delete Fonts
    BaseButton(
      :is-disabled="isEmptyFontFile"
      :is-primary="true"
      @click="submitFontFiles"
    ) Save
  .upload-button__wrapper(v-else)
    BaseButton.cancel-button(
      :is-hollow="true"
      @click="closeFontModal"
    ) Cancel
    BaseButton(
      :is-disabled="isEmptyFontFile"
      :is-primary="true"
      @click="submitFontFiles"
    ) Upload
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import { fontWeights } from '@/assets/scripts/enums';
import helperMixin from '@/components/mixins/helper-mixins';
import FontWeightUploadDropdown from './children/FontWeightUploadDropdown.vue';

export default {
  name: 'BrandTabFontUpload',
  components: {
    FontWeightUploadDropdown,
  },
  mixins: [helperMixin],
  props: {
    isEditCustomFont: { type: Boolean, default: false },
    editFontDetails: { type: Object, default: () => {} },
    uploadedFontFiles: { type: Array, default: () => [] },
    editedItalicFontFiles: { type: Array, default: () => [] },
  },
  data() {
    return {
      editedFontData: {
        text: '',
        font_family: '',
      },

      fontStyleList: [],
      fontNameInput: '',
      hasClickedSubmit: false,
    };
  },
  computed: {
    ...mapGetters('canvasElements', ['getProjectBrandId']),
    tabTitle() {
      return this.isEditCustomFont ? 'Edit Font' : 'Upload Custom Fonts';
    },
    isMissingName() {
      return this.hasClickedSubmit && !this.fontNameInput;
    },
    isEmptyFontFile() {
      return !this.uploadedFontFiles.length && !this.editedItalicFontFiles.length;
    },
    isFontStyleListFilled() {
      if (!this.isEditCustomFont) {
        return this.uploadedFontFiles.length === this.fontStyleList.length;
      }

      return (
        (this.uploadedFontFiles.length + this.editedItalicFontFiles.length) === this.fontStyleList.length
      );
    },
  },

  mounted() {
    if (this.isEditCustomFont) {
      this.initEditCustomFont();
    }
  },

  watch: {
    editFontDetails() {
      this.fontStyleList = []; // since we can select another custom-font when editing a custom-font
      this.initEditCustomFont();
    },
  },

  methods: {
    ...mapActions('team', ['addCustomFonts', 'updateCustomFonts']),
    initEditCustomFont() {
      this.editedFontData = {
        text: this.editFontDetails.text,
        font_family: this.editFontDetails.font_family,
      };
      this.fontNameInput = this.editFontDetails.text;

      this.autoFillFontStyleList(this.uploadedFontFiles);
      if (this.editedItalicFontFiles.length) {
        this.autoFillFontStyleList(this.editedItalicFontFiles, true);
      }
    },
    isNotInitialData(font) {
      return !font.initialData;
    },
    isFontStyleNotSelected(fontName) {
      if (!this.hasClickedSubmit) return false;

      const isSelected = this.fontStyleList.find((item) => item.fontName === fontName); // eslint-disable-line
      return !isSelected;
    },

    addFonts() {
      this.hasClickedSubmit = false;
      this.$emit('add-font', this.$refs.fontUploadInput.files);

      this.$refs.fontUploadInput.value = null;
    },
    deleteTeamFont(index, font, isItalicFont) {
      const filteredFontStyleList = this.fontStyleList.filter((item) => {
        if (item.fontName === font.name) return false;
        return true;
      });

      this.fontStyleList = filteredFontStyleList;
      this.$emit('delete-font', { index, font, isItalicFont });
    },

    changeFontStyle(fontStyle, font) {
      const isAdded = this.fontStyleList.find((item) => item.fontName === font.name); // eslint-disable-line

      if (!isAdded) {
        this.fontStyleList.push({
          fontFile: font,
          fontName: font.name,
          fontStyle: fontStyle.value,
          subFontStyle: fontStyle.subValue,
        });
      } else {
        this.fontStyleList.forEach((item) => {
          if (item.fontName === font.name) {
            item.fontStyle = fontStyle.value; // eslint-disable-line
            item.subFontStyle = fontStyle.subValue; // eslint-disable-line
          }
        });
      }
    },
    autoFillFontStyleList(data, isItalic) {
      data.forEach((item) => {
        const fontStyle = {
          value: '',
          subValue: '',
        };

        fontStyle.value = fontWeights[item.weight];
        fontStyle.subValue = fontWeights[item.weight];

        if (isItalic) {
          fontStyle.subValue = fontStyle.subValue + '-italic'; // eslint-disable-line
        }

        this.changeFontStyle(fontStyle, item);
      });
    },

    submitFontFiles() {
      this.hasClickedSubmit = true;
      const isRegularFontStyleExist = this.fontStyleList.find(
        (font) => font.subFontStyle === 'regular' // eslint-disable-line
      );

      const uploadFontData = {};
      uploadFontData.fontName = this.fontNameInput;

      this.fontStyleList.map((item, index) => {
        /*
          Keys that will be pushed to uploadFontData into camelized format

          extra-light, extra-light-italic, light, light-italic, regular, regular-italic,
          medium, medium-italic, semi-bold, semi-bold-italic, bold, bold-italic
        */
        const camelizedKey = item.subFontStyle
          .toLowerCase()
          .replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());

        uploadFontData[`${camelizedKey}FontName`] = item.fontName;
        // eslint-disable-next-line
        uploadFontData[`${camelizedKey}FontFile`] =
          typeof item.fontFile.file !== 'undefined'
            ? item.fontFile.file // full url of font
            : this.fontStyleList[index].fontFile; // uploaded font file

        return item;
      });

      /* eslint-disable */
      if (!this.fontNameInput.length || !this.isFontStyleListFilled) {
        return;
      } else if (!isRegularFontStyleExist) {
        this.alertWarn('', 'Please Provide a Regular Font Style.', 5000, 'TopCenterNotif');
      } else if (this.isEditCustomFont) {
        uploadFontData.fileId = this.editFontDetails.id;

        this.updateCustomFonts({
          fontData: uploadFontData,
          brandId: this.getProjectBrandId,
        });

        this.closeFontModal({ isResetEditCustomFont: true });
      } else {
        this.addCustomFonts({
          fontData: uploadFontData,
          brandId: this.getProjectBrandId,
        });
        this.closeFontModal();
      }
      /* eslint-enable */
    },
    resetModalData() {
      this.fontNameInput = '';
      this.fontStyleList = [];
      this.hasClickedSubmit = false;
    },
    closeFontModal(data) {
      this.resetModalData();
      this.$emit('close-modal');

      if (data && data.isResetEditCustomFont) {
        this.$emit('reset-edit-custom-font-data');
      }
    },
    moveScroller(event) {
      const canvasModalContent = document.querySelector('.font-overlay .canvas-modal__content');
      const canvasModalContentHeight = (canvasModalContent && canvasModalContent.offsetHeight) || 0;
      const scrollerContainer = document.querySelector('.font-overlay .content');

      const heightToNegate = ((window.innerHeight - canvasModalContentHeight) / 2) + 170; // 170 is canvas modal headers height
      const findExactDropdownPosition = event.pageY - heightToNegate; // decrease with height above "scrollerContainer"
      const scrollerPosition = scrollerContainer.scrollTop;

      let supposedScrollerPosition = findExactDropdownPosition;
      const calcDifference = scrollerPosition - findExactDropdownPosition;
      const needAddition = calcDifference < 285;

      if (needAddition) {
        let addition = 320;
        if (calcDifference > 0) addition = Math.round(findExactDropdownPosition / 1.25);
        supposedScrollerPosition = scrollerPosition + addition;
      }

      const isDropdownOverflowed = supposedScrollerPosition > scrollerPosition;
      if (isDropdownOverflowed) scrollerContainer.scrollTop = supposedScrollerPosition;
    },
  },
};
</script>

<style lang="scss">
.font-overlay__upload {
  position: relative;
  border: 1px solid $lightGrey200;
  border-radius: $componentBorderRadius;
  box-shadow: 0px 0px 1px rgba(10, 31, 68, 0.08), 0px 1px 1px rgba(10, 31, 68, 0.08);
  padding: 20px 15px;

  .icon-cancel {
    position: absolute;
    right: 15px;
    font-size: 16px;
    color: var(--darkgrey800-white);

    &:hover {
      color: var(--darkgrey800-white);
    }
  }

  .title {
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 1.6;
    color: var(--darkgrey800-white);
    margin: 0;
  }

  .upload__group {
    display: flex;
    align-items: center;
    margin: 20px 0;

    .upload__input {
      margin-right: 10px;
      width: calc(100% - 110px);

      .input-field {
        color: var(--darkgrey900-white);
        background: none !important;

        &::placeholder {
          color: var(--darkgrey400-lightgrey500);
        }
      }
    }

    .add-team-custom-font__button {
      position: relative;
      align-self: center;
      min-width: 104px;
    }
  }

  .upload__add-font {
    width: 100%;
    color: $blue800;
    display: flex;
    color: var(--blue800-lightgrey300);
    background: var(--blue200-darkgrey5);
    align-items: center;
    padding: 12px 15px;
    font-size: 0.75rem;

    .btn__content {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .icon-add {
      font-size: 0.875rem;
      color: var(--blue800-green700);
    }
  }

  .team-font__file-upload-btn {
    cursor: pointer;
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    z-index: 3;
    opacity: 0;
    width: 100%;

    &:hover {
      & + .upload__add-font {
        background: var(--blue300-darkgrey7);
      }
    }
  }

  .upload__list {
    .title {
      font-size: 0.75rem;
      text-transform: uppercase;
    }

    .content__alert-notification {
      display: block;
      width: 100%;
      padding: 15px;
      margin: 10px 0;
      font-size: 0.6875rem;
      font-weight: 500;
      line-height: 20px;
      color: $orange900;
      background: $orange200;
      border-radius: 4px;
    }
  }

  .upload-button__wrapper {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;

    button {
      width: 47%;
      height: 38px;
      font-size: 0.8125rem;

      &.cancel-button {
        color: var(--darkgrey900-white);

        &:hover {
          color: unset;
        }
      }
    }
  }

  .empty-text {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 1rem;
    line-height: 28px;
    font-weight: 500;
    height: 120px;
    color: $lightGrey900;
  }
}

.uploaded-font__lists {
  padding: 0;

  .list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;

    .list-text {
      display: block;
      font-size: 0.875rem;
      font-weight: 500;
      color: var(--darkgrey500-lightgrey300);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: calc(100% - 140px);
    }

    .list-buttons {
      display: flex;
      align-items: center;

      .icon-trash-solid {
        color: $red600;
        margin-right: 5px;
        font-size: 0.9375rem;
      }

      .dropdown {
        width: 140px;
      }
    }
  }
}
</style>
